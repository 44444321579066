import { Box, BoxProps, Container, Heading, Stack, useColorModeValue } from "@chakra-ui/react";
import * as React from "react";
import { TabLink } from "./TabLink";
import { Image } from "@chakra-ui/image";

export const PageHeader = (props: BoxProps) => (
  <Box bg={useColorModeValue("white", "gray.900")} py="4" shadow="sm" {...props}>
    <Container maxW="4xl">
      <Image src="logo.png" />
    </Container>
  </Box>
);
