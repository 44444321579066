import * as React from "react";
import { ChakraProvider } from "@chakra-ui/react";
import { Homepage } from "./pages/Homepage";
import { theme } from "./theme";

export const App = () => (
  <ChakraProvider theme={theme}>
    <Homepage />
  </ChakraProvider>
);
