import { Flex, HStack, useColorModeValue as mode } from "@chakra-ui/react";
import * as React from "react";
import { Logo } from "../components/Logo";
import { MobileHamburgerMenu } from "../components/MobileHamburgerMenu";
import { NavMenu } from "../components/NavMenu";
import { Notification } from "../components/Notification";
import { PageContent } from "../components/PageContent";
import { PageHeader } from "../components/PageHeader";
import { ProfileDropdown } from "../components/ProfileDropdown";
import { useMobileMenuState } from "../hooks/useMobileMenuState";

export const Homepage = () => {
  const { isMenuOpen, toggle } = useMobileMenuState();
  return (
    <Flex direction="column" bg={mode("gray.100", "gray.800")} height="100vh">
      <PageHeader />
      <PageContent />
    </Flex>
  );
};
