import { Box, BoxProps, Container, useColorModeValue } from "@chakra-ui/react";
import * as React from "react";

import VideoJS from "../components/VideoJS";

export const PageContent = (props: BoxProps) => {
  const playerRef = React.useRef(null);

  const videoJsOptions = {
    // lookup the options in the docs for more options
    autoplay: true,
    controls: true,
    responsive: true,
    fluid: true,
    sources: [
      {
        src: `https://dominitec.ddns.net/hls/desktop/elencuentro.m3u8`,
        type: "application/x-mpegURL",
      },
    ],
  };

  const handlePlayerReady = (player: any) => {
    playerRef.current = player;

    // you can handle player events here
    player.on("waiting", () => {
      console.log("player is waiting");
    });

    player.on("dispose", () => {
      console.log("player will dispose");
    });
  };

  return (
    <Box as="main" py="8" flex="1" {...props}>
      <Container maxW="7xl">
        <Box bg={useColorModeValue("white", "gray.700")} p="6" rounded="lg" shadow="base">
          <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />
        </Box>
      </Container>
    </Box>
  );
};
